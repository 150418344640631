import Keycloak from 'keycloak-js';
import axios from 'axios';
import { useAuthStore } from '../../store/auth';
import { defineStorageMethod } from '../../helpers/defineStorageMethod';
import { ADD_TO_STORAGE, GET_FROM_STORAGE, REMOVE_FROM_STORAGE } from '../../constants';
import { removeDeviceApi } from '../../api/devices';
import { usePinStore } from '../../store/pin';
import config from '../../helpers/config';
import { actualizeAccountApi } from '../../api/account';

const createKeycloakInstance = () => {
    defineStorageMethod(REMOVE_FROM_STORAGE, 'isJetEmployee');

    return new Keycloak({
        url: config.keycloakUrl,
        realm: 'restaurant',
        clientId: 'live-orders'
    });
};

const createInternalKeycloakInstance = () => {
    defineStorageMethod(ADD_TO_STORAGE, 'isJetEmployee', 'true');

    return new Keycloak({
        url: config.keycloakInternalUrl,
        realm: 'jet',
        clientId: 'tkwy-employee'
    });
};

const isJetEmployee = defineStorageMethod(GET_FROM_STORAGE, 'isJetEmployee') === 'true';
const needToActualize = defineStorageMethod(GET_FROM_STORAGE, 'needToActualizeAccount') === 'true';
let keycloak = isJetEmployee ? createInternalKeycloakInstance() : createKeycloakInstance();

const saveSelectedResturantId = async () => {
    const currentSelectedId = defineStorageMethod(GET_FROM_STORAGE, 'selectedRestaurantId');
    const availableIds: string[] = [];

    if (keycloak.tokenParsed?.atyp === 'chain') {
        await keycloak.loadUserInfo();
        const userInfo = keycloak.userInfo as { rids: string[] };
        availableIds.push(...userInfo.rids);
    } else {
        availableIds.push(...keycloak.tokenParsed?.rids);
    }

    if (!currentSelectedId || !availableIds.includes(currentSelectedId)) {
        defineStorageMethod(ADD_TO_STORAGE, 'selectedRestaurantId', availableIds[0]);
    }
};

/**
 * For Jet employees we need to pass restaurant_id & tenant to the login url
 * This is not possible with the keycloak-js library, so we need to redirect manually
 */
const loginAsJetEmployee = async () => {
    const isAuthenticated = await keycloak.init({ checkLoginIframe: false });

    if (isAuthenticated && keycloak.token) {
        await saveSelectedResturantId();
        useAuthStore.getState().actions.loginSuccess(keycloak.token);
        return;
    }

    const selectedRestaurantId = defineStorageMethod(GET_FROM_STORAGE, 'selectedRestaurantId');
    const loginUrl = keycloak.createLoginUrl({ idpHint: 'okta' });

    window.location.href = `${loginUrl}&tenant=legacy-tkwy&restaurant_id=${selectedRestaurantId}`;
};

export const login = async () => {
    if (window.Cypress) {
        const token = defineStorageMethod(GET_FROM_STORAGE, 'testToken');
        useAuthStore.getState().actions.loginSuccess(token || '');
        return;
    }

    if (isJetEmployee) {
        return loginAsJetEmployee();
    }

    const isAuthenticated = await keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false });

    if (isAuthenticated && keycloak.token && keycloak.tokenParsed) {
        await saveSelectedResturantId();

        if (needToActualize) {
            defineStorageMethod(REMOVE_FROM_STORAGE, 'needToActualizeAccount');
            await actualizeAccountApi();
        }

        useAuthStore.getState().actions.loginSuccess(keycloak.token);
    } else {
        defineStorageMethod(ADD_TO_STORAGE, 'needToActualizeAccount', 'true');
        keycloak.login({ redirectUri: window.location.href });
    }
};

export const loginByCode = async (code: string) => {
    const response = await axios.post(
        `${config.keycloakInternalUrl}/realms/jet/protocol/openid-connect/token`,
        {
            client_id: 'tkwy-employee',
            grant_type: 'authorization_code',
            code,
            redirect_uri: window.location.origin + window.location.pathname
        },
        {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        }
    );

    keycloak = createInternalKeycloakInstance();

    const isAuthenticated = await keycloak.init({
        token: response.data.access_token,
        refreshToken: response.data.refresh_token,
        idToken: response.data.id_token,
        checkLoginIframe: false
    });

    if (isAuthenticated && keycloak.tokenParsed && keycloak.token) {
        const { rids } = keycloak.tokenParsed;

        defineStorageMethod(ADD_TO_STORAGE, 'selectedRestaurantId', rids[0]);
        useAuthStore.getState().actions.loginSuccess(keycloak.token);
    } else {
        throw new Error('Login failed');
    }
};

export const getAccessToken = async () => {
    // For smoke tests & Cypress
    const testToken = defineStorageMethod(GET_FROM_STORAGE, 'testToken');
    if (testToken) return testToken;

    await refreshTokenIfExpired();

    return keycloak.token;
};

let refreshTokenPromise: Promise<any> | null = null;

export const refreshTokenIfExpired = async () => {
    if (refreshTokenPromise) {
        return refreshTokenPromise;
    }

    if (!keycloak.isTokenExpired(30)) {
        return;
    }

    refreshTokenPromise = keycloak
        .updateToken(30)
        .then(() => (refreshTokenPromise = null))
        .catch(() => keycloak.login({ redirectUri: window.location.href }));

    return refreshTokenPromise;
};

const disablePushNotifications = async () => {
    const fcmToken = defineStorageMethod(GET_FROM_STORAGE, 'fcmToken');

    if (!fcmToken) {
        return;
    }

    return removeDeviceApi(fcmToken).finally(() => {
        defineStorageMethod(REMOVE_FROM_STORAGE, 'fcmToken');
    });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const logout = async (_reason?: string) => {
    useAuthStore.getState().actions.logoutStarted();

    await disablePushNotifications();

    const keysToRemoveFromStorage = ['isJetEmployee', 'realm', 'clientId', 'selectedRestaurantId'];
    keysToRemoveFromStorage.forEach((key) => defineStorageMethod(REMOVE_FROM_STORAGE, key));

    await usePinStore.getState().actions.setToken(null);

    keycloak.logout();
};
