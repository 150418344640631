import axios from 'axios';
import { defineStorageMethod } from '../helpers/defineStorageMethod';
import { GET_FROM_STORAGE } from '../constants';
import config from '../helpers/config';
import { usePinStore } from '../store/pin';
import { getAccessToken } from '@lo/shared/services/auth';

const axiosSetup = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    }
});

axiosSetup.interceptors.request.use(async (axiosConfig) => {
    const pinToken = usePinStore.getState().token;
    const token = await getAccessToken();
    const selectedRestaurantId = defineStorageMethod(GET_FROM_STORAGE, 'selectedRestaurantId');
    const tenant = defineStorageMethod(GET_FROM_STORAGE, 'tenant');

    if (token) {
        axiosConfig.headers['Authorization'] = `Bearer ${token}`;
    }

    if (pinToken) {
        axiosConfig.headers['X-pin-token'] = pinToken;
    }

    if (selectedRestaurantId) {
        axiosConfig.headers['X-Restaurant-Id'] = selectedRestaurantId;
    }

    if (tenant) {
        axiosConfig.headers['X-Tenant'] = tenant;
    }

    return axiosConfig;
});

export default axiosSetup;
